/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Index from "./index.pt"

const Home = () => {

  return (
    <>
      <Index />
    </>
  )
}

export default Home
